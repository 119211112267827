<template>
  <aside>
    <header>
      <h2>Log Viewer</h2>
      <button class="close" @click="close"><span class="sr-only">Close modal window</span></button>
    </header>

    <main>
      <pre ref="logdata"></pre>
    </main>
  </aside>
</template>

<script>
export default {
  props: ['data'],
  data() {
    return {
      log_data: null
    }
  },
  mounted() {
    this.populateLog(this.data.integration_id, this.data.id)
  },
  methods: {
    close() {
      this.$emit('done')
    },
    async populateLog(int_id, log_id) {
      let resp = await this.$api.Integrations.get_log(int_id, log_id)
      this.$refs['logdata'].innerText = resp;
      resp = null;
    }
  }
}
</script>